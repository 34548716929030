document.addEventListener("turbo:load", () => {
  const goToCheckout = document.querySelectorAll(".sb-go-to-checkout");
  const buyNowBtn = document.querySelector(".sb-checkout-down")

  goToCheckout.forEach(checkout => {
    checkout.addEventListener("click", (e) => {
      window.location.href = `/products/${document.querySelector('input[name="option"]:checked').value}/new`
    })
  });

  buyNowBtn.addEventListener("click", (e) => {
    window.location.href = `/products/${document.querySelector('input[name="option"]:checked').value}/new`
  })
})
