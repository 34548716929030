document.addEventListener("turbo:load", () => {
  const faqQuestionsAnd = document.querySelectorAll(".sb-faq-question-and-arrow");

  faqQuestionsAnd.forEach((faqQuestion) => {
    faqQuestion.addEventListener("click", (e) => {
      if (faqQuestion.classList.contains("faq-question-open")) {
        faqQuestion.classList.remove("faq-question-open");
        faqQuestion.parentNode.querySelector(".sb-faq-answer").style.height = "0px";
      } else {
        const isOpen = document.querySelector(".faq-question-open");
        if (isOpen) {
          isOpen.classList.remove("faq-question-open");
          isOpen.parentNode.querySelector(".sb-faq-answer").style.height = "0px";
        }
        faqQuestion.classList.add("faq-question-open");
        faqQuestion.parentNode.querySelector(".sb-faq-answer").style.height = "fit-content";
      }
    })
  })
})
