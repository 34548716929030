document.addEventListener("turbo:load", (e) => {
  const bottomPart = document.querySelector(".sb-bottom-fixed-part");
  let lastScrollY = window.scrollY;

  window.addEventListener("scroll", () => {
    if (window.scrollY > lastScrollY) {
      // Scrolling down
      bottomPart.style.bottom = "-100vh";
    } else {
      // Scrolling up
      bottomPart.style.bottom = "0";
    }
    lastScrollY = window.scrollY;
  });
});
