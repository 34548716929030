document.addEventListener("turbo:load", () => {
  const sbMoreText = document.querySelectorAll(".sb-more-text-title");
  sbMoreText.forEach((text) => {
    text.addEventListener("click", () => {
      if (text.classList.contains("sb-is-open")) {
        text.classList.remove("sb-is-open");
        text.parentNode.querySelector(".sb-more-text-text").style.height = "0px"
      } else {
        const isOpen = document.querySelector(".sb-is-open");
        if (isOpen) {
          isOpen.classList.remove("sb-is-open");
          isOpen.parentNode.querySelector(".sb-more-text-text").style.height = "0px";
        }
        text.parentNode.querySelector(".sb-more-text-text").style.height = "fit-content";
        text.classList.add("sb-is-open")
      }

    })
  })
})
